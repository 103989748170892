<template>
  <!-- 业务占比 -->
  <div
    class="type_chart"
    id="type_chart"
  ></div>
</template>

<script>
import echarts from "echarts";
let _this;
export default {
  name: "business",
  data() {
    return {
      option:{
      title: {
        text: "主营业务占比",
        x: "center",
        y: "center",
        textStyle: {
          fontWeight: "normal",
          fontSize: window.innerHeight*0.020,
          color: "#ffff",
        }, // 标题
        subtextStyle: {
          fontWeight: "normal",
          fontSize: 17,
          color: "#00eeff",
        }, // 副标题
      },
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c} ({d}%)",
      },
      legend: {

        // 图例样式
        icon: "circle",
        orient: "horizontal",
        right: 20,
        top: "middle",


        width: "100",

        padding: [150, 0, 0, 0],
        itemWidth: 30,

        data: ["代驾", "跑腿", "检车"],

        textStyle: {
          color: ["#22b573", "#0B6CCE", "#FF7F50"],
          fontSize: window.innerHeight*0.012,
        },
      },
      color: ["#22b573", "#0B6CCE", "#FF7F50"],
      series: [
        {
          name: "",

          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
            // normal:{
            //    formatter: "{d}%", //模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
            // }
          },
          emphasis: {
            label: {
              show: false,
              fontSize: "30",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 335, name: "代驾" },
            { value: 310, name: "跑腿" },
            { value: 234, name: "检车" },
          ],

        },
      ],
    }
    };
  },
  created() {
    _this = this;
  },
  mounted() {

     _this.$post("/statistics/trade")
        .then((res) => {
          console.log(_this.option.series[0].data);
           _this.option.series[0].data = res.data

            var myChart = echarts.init(document.getElementById("type_chart"));

            myChart.setOption(_this.option);

        })
        .catch((err) => {});



  },
};
</script>
<style scoped>
.type_chart {
  width: 100%;
  height: 100%;
}
</style>