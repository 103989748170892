<template>

  <div
    class="map"
    id="map"
  >
  </div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
let _this, map, layer,myChart;
import echarts from "echarts";
import "./china.js";

export default {
  name: "amap",
  // components: {
  //   HelloWorld,
  // },
  data() {
    return {
      // 取消最后一次选中标记
      flag: false,

      // 控制地图选中index
      active_index: 0,

      geoMapData:[]

    };
  },
  created() {
    _this = this;
  },
  mounted() {
    _this.getData()

    _this.activeMap()
  },
  methods: {
    getData(){
      _this.$post("/statistics/provinceOrderNum")
        .then((res) => {

            _this.geoMapData = res.data
            // console.log(_this.order_num);
            setTimeout(() => {
              _this.getData()
            }, 300000);

            _this.renderEcharts()

      })
      .catch((err) => {});
    },
    renderEcharts() {
      myChart = echarts.init(document.getElementById("map"));

      // 已经开通的省份
      let activeCity = []
      for (let i = 0; i < _this.geoMapData.length; i++) {

        activeCity.push({
          name:_this.geoMapData[i].name,
          itemStyle: {
            areaColor: '#6ac9ff',
            color: '#6ac9ff'
          }
        })

      }

      // console.log(activeCity);


      var option = {
        animation: true,
        // 地图背景颜色
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
        },
        geo: {
          map: "china",
          // silent: true,
          roam: true,
          zoom: 1.2, // 地图初始大小
          // center: [116.366794, 40.400309], // 初始中心位置
          label: {
            normal: {
              show: true,
              color: "#fff",

            },
            emphasis: {
              show: false,
              color: "#fff",

            },
          },
          data:_this.geoMapData,
          regions: activeCity, //已经开通的省份的样式
          itemStyle: {
            normal: {
              areaColor: "#2472d6", // 区域块颜色
              borderColor: "#4eaaff ",
            },
            emphasis: {
              areaColor: "#f6bd5e", //鼠标经过的颜色
            },
          },


        },

        series: [],
      };

      function renderEachCity() {
        var option = {
          xAxis: [],
          yAxis: [],
          grid: [],
          series: [],
        };
        // var inflationStartIdx = 14;
        // var inflationYearCount = 3;
        // var inflationYearStart = '2006';
        // var xAxisCategory = [];
        // for (var i = 0; i < inflationYearCount; i++) {
        //     xAxisCategory.push((+inflationYearStart + i) + '');
        // }

        echarts.util.each(_this.geoMapData, function (dataItem, idx) {
          // console.log(dataItem);
          // console.log(idx);
          // console.log(dataItem.location);
          var geoCoord = dataItem.location;
          var coord = myChart.convertToPixel("geo", geoCoord);
          // var boundL = -12.782788213627585;
          // var boundR = 35.92763028872384;
          // var boundT = 32.22854555899493;
          // var boundB = 95.18817097360194;
          // if (!coord ||
          //     geoCoord[0] < boundL ||
          //     geoCoord[0] > boundR ||
          //     geoCoord[1] > boundB ||
          //     geoCoord[1] < boundT
          // ) {
          //     return;
          // }
          idx += "";

          // for (var k = 0; k < inflationYearCount; k++) {
          //     inflationData.push(dataItem[inflationStartIdx + k]);
          // }

          option.xAxis.push({
            id: idx,
            gridId: idx,
            type: "category",
            show:false,
            // name: dataItem.name,
            // nameStyle: {
            //     color: 'red',
            //     fontSize: 12
            // },
            nameLocation: "middle",
            nameGap: 3,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#fff", //文字颜色
              },
            },
            // data: xAxisCategory,
            data: [_this.geoMapData[idx].name], //城市名称
            z: 100,
          });
          option.yAxis.push({
            id: idx,
            gridId: idx,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#1C70B6",
              },
            },
            z: 100,
          });
          // console.log(coord);
          // console.log(window.innerHeight/10,'==================');
          option.grid.push({
            id: idx,
            width: 10,
            height:0,
              // _this.geoMapData[idx].num > window.innerHeight
              //   ? [window.innerHeight / 10]
              //   : [_this.geoMapData[idx].num / 10], // 柱状图高度 ------ 最大高度为窗口高度除10
            left: coord[0] - 10 ,
            top:coord[1] - 15,
            //  _this.geoMapData[idx].num > window.innerHeight ? coord[1] - 100 : coord[1] - _this.geoMapData[idx].num / 10,
            z: 100,
          });

          option.series.push({
            barWidth: 10,
            id: idx,
            type: "bar",
            xAxisId: idx,
            yAxisId: idx,
            barMinHeight: 500,
            hoverAnimation: true,
            // data: inflationData,
            data: [_this.geoMapData[idx].num], // 城市数据
            z: 100,
            itemStyle: {
              color: "#f1910e",
              borderRadius: [10, 10, 0, 0],
            },
            tooltip: {
              trigger: "none",
              formatter: "{b}:{c}",
              backgroundColor: "rgba(255,255,255,.8)",
              padding: [10, 10, 10, 10],
              position: "top",
              borderRadius: 5,
              textStyle: {
                color: "#000",
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: "{b} : {num|{c}} 单",
                backgroundColor: "#5549f2",
                padding: [10, 10, 10, 10],
                position: "top",
                borderRadius: 5,
                color: "#FFF",
                fontSize: window.innerHeight * 0.012,
                rich: {
                  num: {
                    color: "#f2d839",
                    fontSize: window.innerHeight * 0.018,
                    fontWeight: "bold",
                  },
                },
              },
            },
          });
        });

        myChart.setOption(option);
      }

      setTimeout(renderEachCity, 0);
      // 缩放和拖拽
      function throttle(fn, delay, debounce) {
        var currCall;
        var lastCall = 0;
        var lastExec = 0;
        var timer = null;
        var diff;
        var scope;
        var args;

        delay = delay || 0;

        function exec() {
          lastExec = new Date().getTime();
          timer = null;
          fn.apply(scope, args || []);
        }

        var cb = function () {
          currCall = new Date().getTime();
          scope = this;
          args = arguments;
          diff = currCall - (debounce ? lastCall : lastExec) - delay;

          clearTimeout(timer);

          if (debounce) {
            timer = setTimeout(exec, delay);
          } else {
            if (diff >= 0) {
              exec();
            } else {
              timer = setTimeout(exec, -diff);
            }
          }

          lastCall = currCall;
        };

        return cb;
      }

      var throttledRenderEachCity = throttle(renderEachCity, 0);
      myChart.on("geoRoam", throttledRenderEachCity);
      myChart.setOption(option);

      // 获取横纵坐标
      function getMousePos(e) {
        var e = event || window.event;
        var scrollX =
          document.documentElement.scrollLeft || document.body.scrollLeft;
        var scrollY =
          document.documentElement.scrollTop || document.body.scrollTop;
        var x = e.pageX || e.clientX + scrollX;
        var y = e.pageY || e.clientY + scrollY;
        // console.log(x,y)
        return { x: x, y: y };
      }

      // 鼠标的点击事件
      myChart.on("click", function (params) {
        console.log(params); //此处写点击事件内容

      });

      // 地图
      // var myecharts = echarts.init(document.getElementById('map'))
      // console.log(myecharts);
      // myecharts.setOption(_this.option)
    },
    activeMap(){
      _this.$nextTick(function () {
        setInterval(() => {
          if (_this.active_index != 0 && _this.flag == false) {
            myChart.dispatchAction({
              geoIndex: 0,
              type: "geoUnSelect",
              geoName: "china",
              name: _this.geoMapData[_this.active_index - 1].name,
            });

            myChart.dispatchAction({
              type: "downplay",
              seriesIndex: _this.active_index - 1,
              dataIndex: 0,
            });
          } else if (_this.active_index == 0 && _this.flag == true) {
            myChart.dispatchAction({
              geoIndex: 0,
              type: "geoUnSelect",
              geoName: "china",
              name: _this.geoMapData[_this.geoMapData.length - 1].name,
            });

            myChart.dispatchAction({
              type: "downplay",
              seriesIndex: _this.geoMapData.length - 1,
              dataIndex: 0,
            });
          }

          // 选中地图块
          myChart.dispatchAction({
            geoIndex: 0,
            type: "geoSelect",
            geoName: "china",
            name: _this.geoMapData[_this.active_index].name,
          });
          // console.log(_this.geoMapData[_this.active_index].name);
          // console.log(myChart);

          // 柱状图高亮
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: _this.active_index,
            dataIndex: 0,
          });

          if (_this.active_index < _this.geoMapData.length - 1) {
            _this.active_index++;
            _this.flag = false;
          } else {
            _this.active_index = 0;
            _this.flag = true;
          }
        }, 3000);
      });

    }
  },
};
</script>
<style scope>
</style>
