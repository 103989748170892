<template>
  <!-- 市场分布 -->

  <div class="main" id="main"></div>
</template>
<script>
import echarts from "echarts";
let _this;
export default {
  name: "market",
  data() {
    return {
      option:{
      title: {
        text: "全国TOP.6",
        x: "center",
        y: "center",
        textStyle: {
          fontWeight: "bold",
          fontSize: window.innerHeight*0.020,
          color: "#6CE4E6",
        }
      },
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      color: [
        "#FFB77D",
        "#58F5FA",
        "#1B96FF",
        "#38E5A7",
        "#CFABFF",
        "#FFFA7D",
      ],
      series: [
        {
          name: "全国TOP.6",
          type: "pie",
          radius: ["42%", "53%"], // 半径
          center: ["50%", "51%"], // 位置
          label: {
            normal: {
              formatter: "{b}\n {c}",
              fontSize: window.innerHeight*0.015,
            },
          },
          data: [],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
            },
          },
        },
      ],
    }
    };
  },
  created() {
    _this = this;
  },
  mounted() {


      _this.$post("/statistics/top6")
        .then((res) => {



          _this.option.series[0].data = res.data

          //   _this.order_num = res.data.count
          //   // console.log(_this.order_num);
          //  setTimeout(() => {
          //     _this.getOrderNum()
          //   }, 2000);

          var myChart = echarts.init(document.getElementById("main"));
          // 指定图表的配置项和数据

          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(_this.option);
        })
        .catch((err) => {});




  },
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100%
}
</style>