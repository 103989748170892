import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'; // AJAX封装类
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import {
  config
} from './utils/config';
import {
  post
} from './utils/http';
import './utils/rem'
Vue.config.productionTip = false;
Vue.prototype.$post = post;
Vue.prototype.config = config;
Vue.use(ElementUI, {
  size: 'small'
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
