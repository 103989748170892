import axios from 'axios'; // AJAX封装类
import {
	config
} from './config'; //配置

import {
	formFilter
} from './form'; // 引入表单验证模块

axios.defaults.timeout = config.axios_timeout; //请求超时20秒
axios.defaults.baseURL = config.axios_baseURL; //请求base url
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; //设置post请求时的header信息

// axios.defaults.headers['platform'] = 'pc';
// axios.defaults.headers['port'] = 'admin';
// axios.defaults.headers['app-version'] = '100';
// axios.defaults.headers['registration-id'] = '';



/**
 * 封装post请求
 */

export function post(url, data = {}, rules = {},mode) {

	//如果传值中，有$rules参数，就验证表单
	if (rules !== {}) {

		//表单验证,方法在form.js
		var isReturn = formFilter(this, data, rules);

		if (isReturn == false) {
			//通知submitBtn全局组件修改按钮状态

			return;

		}

	}

	// if(mode === 'normal'){
	// 	axios.defaults.baseURL = config.axios_normalURL; //请求normal url
	// }else {
	// 	axios.defaults.baseURL = config.axios_baseURL; //请求base url
	// }

	return new Promise((resolve, reject) => {
		// axios.defaults.headers['token'] = localStorage.getItem(config.localStorage_token_name);
		// if(localStorage.getItem('city_tag')!=null){
		// 	axios.defaults.headers['city-tag'] = localStorage.getItem('city_tag');
		// }else{
		// 	axios.defaults.headers['city-tag'] = '';
		// }

		//判断，是否处于login控制器
		if (this.$route.path == '/login') {

			//处于login控制器



		} else {




			if (typeof (axios.defaults.headers['token']) != 'string' || axios.defaults.headers['token'] == '') {



			}


		}




		//设置一个遮罩层缓存
		// var loading;

		// // 获取时间戳
		// var randNum = Math.round(new Date() / 1000).toString();

		// localStorage.setItem(randNum, 1);

		// setTimeout(() => {

		// 	if (localStorage.getItem(randNum) == 1) {

		// 		//服务器加载过程中的遮罩层
		// 		loading = this.$loading({
		// 			lock: true,
		// 			text: '加载中…',
		// 			spinner: 'el-icon-loading',
		// 			background: 'rgba(255, 255, 255, 0.7)'
		// 		});

		// 	}

		// }, 8000);







		axios.post(url, data).then(response => {


			switch (response.data.code) {

				//200 成功，返回数据给控制器处理业务
				case 200:

					resolve(response.data);


					break;

					//102 普通错误，弹窗输出错误即可
				case 102:

					this.$message.error({
						message: response.data.msg,
						duration: 1500
					});


					break;

					//402 普通错误，弹窗输出错误即可
				case 402:

					this.$message.error({
						message: response.data.msg,
						duration: 1500
					});



					break;

					//404 普通错误，弹窗输出错误即可
				case 404:
					console.log(response);
					this.$message.error({
						message: response.data.msg,
						duration: 1500
					});



					break;

					//500 普通错误，弹窗输出错误即可
				case 500:

					this.$message.error({
						message: response.data.msg,
						duration: 1500
					});

					break;

					//1001 登录状态失效，踢人
				case 1001:




					break;

					//888 账户未激活，展示谷歌相关插件，引导用户完成验证，在login控制器里操作
				case 888:

					resolve(response);

					this.$message(response.data.msg);


					break;

				default:

					this.$message.error('无效状态码，请联系技术人员');



					break;


			}

			// 请求成功后，如果loading存在则关闭loading遮罩
			// if (loading) {
			// 	loading.close();
			// }

			// // 删除缓存，不加载遮罩
			// localStorage.removeItem(randNum);





		}, err => {

			//关闭遮罩层

			// if (loading) {
			// 	loading.close();
			// }
			// //通知submitBtn全局组件修改按钮状态
			// bus.$emit('btnStatus',false);
			// this.$alert('连接服务器失败，请重试', '错误提示', {
			// 	confirmButtonText: '确定',
			// 	callback: action => {

			// 		// this.$router.go(0);

			// 	}
			// });

			console.log(err);


			reject(err);
		})
	})


}
