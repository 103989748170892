<template>
  <div
    class="passenger"
    id="passenger"
  ></div>
</template>

<script>
import echarts from "echarts";
let _this;
export default {
  name: "driverCount",
  data() {
    return {
      search_data: {
        page: 1,
        limit: 6,
      },
      option: {
        title: {
          // text: 'World Population'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "#2D8EC0",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: window.innerHeight * 0.01,
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "category",
          data: ["石家庄", "济南", "郑州", "秦皇岛", "西安", "长春", "太原"],
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "#2D8EC0",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: window.innerHeight * 0.012,
              fontWeight:'bold',
              color: "#fff",
            },
          },
        },
        legend: {
          // orient: "vertical",
          // left: "right",
          // top: "middle",
          textStyle: {
            color: "#2D8EC0",
            fontSize: window.innerHeight * 0.012,
          },
        },
        series: [
          {
            name: "本月",
            type: "bar",
            data: [320, 332, 301, 334, 390, 330, 320],
            itemStyle: {
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(106, 61, 225, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(91, 166, 247, 1)", // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            name: "上月",
            type: "bar",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            itemStyle: {
              // borderRadius: 100,
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(206, 91, 87, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(241, 234, 166, 1)", // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ],
      },
    };
  },
  created() {
    _this = this;
  },

  mounted() {
    _this.getData();
  },

  methods: {
    getData() {
      _this
        .$post("/system/getConsumerCount", _this.search_data)
        .then((res) => {
          _this.option.yAxis.data = [];

          _this.option.series[0].data = [];

          _this.option.series[1].data = [];

          for (let i = 0; i < res.data.result.length; i++) {
            if (res.data.result[i].city_name.length > 3) {
              _this.option.yAxis.data.push(
                res.data.result[i].city_name.slice(0, 3) + "..."
              );
            } else {
              // 城市名
              _this.option.yAxis.data.push(res.data.result[i].city_name);
            }

            // 本月数据
            _this.option.series[0].data.push(
              res.data.result[i].new_consumer_count
            );

            // 上月数据
            _this.option.series[1].data.push(res.data.result[i].consumer_count);
          }

          _this.renderEcharts();

          _this.$nextTick(function () {
            if (
              res.data.count >
              _this.search_data.page * _this.search_data.limit
            ) {
              // 如果总数 >  页码*每次请求数量
              _this.search_data.page += 1;
            } else {
              _this.search_data.page = 1;
            }

            setTimeout(() => {
              _this.getData();
            }, 5000);
          });
        })
        .catch((err) => {});
    },
    renderEcharts() {
      var myChart = echarts.init(document.getElementById("passenger"));
      myChart.setOption(_this.option);
    },
  },
};
</script>
<style scoped>
.passenger {
  width: 100%;
  height: 100%;
}
</style>