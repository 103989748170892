
/**
 * 表单过滤
 */
var msgLog;
export function formFilter(_this, data, rules) {

	//便利表单对象，根据表单的rules值，验证相应的value是否符合要求
	for (let key in rules) {

		//判定是否为必填
		if (rules[key].rules.required == true) {

			if (typeof (data[key]) == 'undefined' || data[key] == null || data[key].length == 0) {

				if(msgLog){

					msgLog.close();

				}

				msgLog = _this.$message.error(rules[key].placeholder + "为必填项");


				return false;

			}

		}

		data[key] = (data[key] == null) ? '' : data[key];

		if (data[key] == '') {

			continue;

		}

		//走regulation的验证
		switch (rules[key].rules.regulation) {

			case 'none':
				//什么都不用做
				break;

			case 'special':

				if (rules[key].rules.rulesData == 'json_array') {

					if (typeof rules[key] == 'string') {
						try {
							JSON.stringify(rules[key])
							return true;
						} catch (e) {
							console.log(e);
							if(msgLog){

								msgLog.close();

							}
							msgLog = _this.$message.error(rules[key].rules.message);

							return false;
						}
					}

					//判定 data[key] 是不是json

				}
				// 验证用户管理   用户名
				if (rules[key].rules.rulesData == 'user_name') {


					if (data[key].length < 8 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				// 验证用户管理   昵称
				if (rules[key].rules.rulesData == 'user_name') {


					if (data[key].length < 1 || data[key].length > 12) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				// 角色唯一编码验证
				if (rules[key].rules.rulesData == 'table_code') {


					if (data[key].length < 14 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				// 公司唯一编码验证   editCity_code
				if (rules[key].rules.rulesData == 'company_code') {

					if (data[key].length < 14 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				if (rules[key].rules.rulesData == 'editCity_code') {

					if (data[key].length <= 14 || data[key].length >= 16) {

						// _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				// 判断城市唯一编码 conf_value
				if (rules[key].rules.rulesData == 'city_code') {

					if (data[key].length < 14 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}

				// 判断优惠卷模型编码 conf_value
				if (rules[key].rules.rulesData == 'coupon_code') {

					if (data[key].length < 14 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}

				// 判断模式唯一编码 conf_value
				if (rules[key].rules.rulesData == 'order_code') {

					if (data[key].length < 14 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}

				// 判断司机唯一编码 conf_value
				if (rules[key].rules.rulesData == 'driver_code') {

					if (data[key].length < 14 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				// 判断模式唯一编码 conf_value
				if (rules[key].rules.rulesData == 'mode_code') {

					if (data[key].length < 14 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				// 判断模式唯一编码 conf_value
				if (rules[key].rules.rulesData == 'consumer_code') {

					if (data[key].length < 14 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}

						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				// 验证锁定值   验证阈值
				if (rules[key].rules.rulesData == '0|1') {
					if (data[key].length !== 1) {
						console.log(data[key]); //在这里可以输入1或者0
						if(msgLog){

							msgLog.close();

						}

						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}

				// 验证网址格式
				if (rules[key].rules.rulesData == 'weburl') {

					var check = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;

					if (check.test(data[key]) !== true) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}

				// 验证身份证
				if (rules[key].rules.rulesData == 'id_card') {

					var check = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

					if (check.test(data[key]) !== true) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}

				// 验证驾驶证型号
				if (rules[key].rules.rulesData == 'driverLicense') {
					var check = /^.*[a-z]+.*$/;
					if (check.test(data[key])) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}

				// 验证添加配置 的   conf_key标识符
				if (rules[key].rules.rulesData == 'conf_key') {

					if (data[key].length < 1 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog  = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}
				// 验证添加配置 的   conf_value值
				if (rules[key].rules.rulesData == 'conf_value') {

					if (data[key].length < 1 || data[key].length > 1000) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}

				if (rules[key].rules.rulesData == 'belong') {

					if (data[key].length < 1 || data[key].length > 20) {
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error(rules[key].rules.message);

						return false;

					}

				}





				break;

				//大于
			case 'gt':

				if (data[key] <= rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;

				//大于等于
			case 'egt':

				if (data[key] < rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;

				//小于
			case 'lt':

				if (data[key] >= rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;

				//小于等于
			case 'elt':

				if (data[key] > rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;

				//等于
			case 'eq':

				if (data[key] !== rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;

				//区间于
			case 'between':

				if (data[key] < rules[key].rules.rulesData[0] || data[key] > rules[key].rules.rulesData[1]) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;



				//字符串长度大于
			case 'gt_str':

				if (data[key].length <= rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;

				//字符串长度大于等于
			case 'egt_str':

				if (data[key].length < rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;

				//字符串长度小于
			case 'lt_str':

				if (data[key].length >= rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}


				break;

				//字符串长度小于等于
			case 'elt_str':

				if (data[key].length > rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}


				break;

				//字符串长度等于
			case 'eq_str':

				if (data[key].length !== rules[key].rules.rulesData) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;


				//字符串长度在XX和XX之间
			case 'between_str':

				if (data[key].length < rules[key].rules.rulesData[0] || data[key].length > rules[key].rules.rulesData[1]) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;


				//必须是X位数字
			case 'eq_num':

				switch (rules[key].rules.rulesData) {

					case 6:

						var check = /^\d{6}$/; /*正则表达式*/

						break;

					case 8:

						var check = /^\d{8}$/; /*正则表达式*/

						break;
					case 11:

						var check = /^\d{11}$/; /*正则表达式*/

						break;
					default:
						if(msgLog){

							msgLog.close();

						}
						msgLog = _this.$message.error("formRules." + key + ".rules.rulesData参数错误");

						break;



				}

				if (check.test(data[key]) !== true) {
					if(msgLog){

						msgLog.close();

					}
					msgLog = _this.$message.error(rules[key].rules.message);

					return false;

				}

				break;

				//收费综合指数
			case 'synthesize':


				var data_arrway = JSON.parse(data[key]);


				for (let i = 0; i < data_arrway.length; i++) {

					for (const j in data_arrway[i]) {

						// 提交数据的值
						switch (rules[key].rulesData[j].collocation[0]) {
							case "==":

								if (!(data_arrway[i][j].length == rules[key].rulesData[j].collocation[1])) {
									if(msgLog){

										msgLog.close();

									}
									msgLog = _this.$message.error(rules[key].rules.message);
									return false;

								}

								break;
							case ">":

								if (data_arrway[i][j] == '' && j.slice(0,5) != 'short') {

									if(msgLog){

										msgLog.close();

									}
									msgLog = _this.$message.error('数据不能为空');



									return false;

								} else if (!(data_arrway[i][j] > rules[key].rulesData[j].collocation[1])) {
									if(msgLog){

										msgLog.close();

									}
									msgLog = _this.$message.error(rules[key].rules.message);
									return false;

								}

								break;
							case ">=":

								if (data_arrway[i][j] == '' && j.slice(0,5) != 'short') {

									if(msgLog){

										msgLog.close();

									}
									msgLog = _this.$message.error('数据不能为空');

									return false;

								} else if (!(data_arrway[i][j] >= rules[key].rulesData[j].collocation[1])) {
									if(msgLog){

										msgLog.close();

									}
									msgLog = _this.$message.error(rules[key].rules.message);
									return false;

								}

								break;
						}

					}

				}

				break;


			default:
				if(msgLog){

					msgLog.close();

				}
				msgLog = _this.$message.error("formRules." + key + ".rules.regulation参数错误");

				return false;

				break;
		}
	}

	return true;

}