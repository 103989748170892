<template>
  <div>
    <div class="home">

      <img
        src="../assets/image/logo.png"
        alt=""
        class="logo"
      >

      <div class="left">
        <div class="market">
          <div class="title-o">
            <img
              src="../assets/image/round.gif"
              width="20"
              height="20"
              alt=""
            />
            <h4 class="distributed">市场分布</h4>
          </div>

          <div class="item">
            <market></market>
          </div>
        </div>
        <div class="oddnumb">
          <div class="title-o">
            <img
              src="../assets/image/round.gif"
              width="20"
              height="20"
              alt=""
            />
            <h4 class="distributed">单数对比</h4>
          </div>
          <div class="item">
            <oddnumber></oddnumber>
          </div>
        </div>
        <div class="Lastweek">
          <div class="title-o">
            <img
              src="../assets/image/round.gif"
              width="20"
              height="20"
              alt=""
            />
            <h4 class="distributed">上周收入</h4>
          </div>
          <div class="item">
            <lastweek></lastweek>
          </div>
        </div>
      </div>
      <!-- 地图 -->

      <div class="center">

        <div class="center-top">
          <div class="order-num">
            <span v-for="item in order_num">{{item}}</span>
          </div>
          <div class="center-top-bg">
            <p>平台单数统计</p>
          </div>
        </div>

        <div class="map-box">
          <div class="title-o">
            <img
              src="../assets/image/round.gif"
              width="20"
              height="20"
              alt=""
            />
            <h4 class="distributed">各省情况</h4>
          </div>
          <amap class="amap"></amap>

          <img
            src="../assets/image/bg-bot.gif"
            alt=""
            class="map-img"
          >
        </div>
      </div>

      <div class="right">
        <div class="busine">
          <div class="title-o">
            <img
              src="../assets/image/round.gif"
              width="20"
              height="20"
              alt=""
            />
            <h4 class="distributed">业务占比</h4>
          </div>
          <div class="item">
            <business></business>
          </div>
        </div>
        <div class="driver">
          <div class="title-o">
            <img
              src="../assets/image/round.gif"
              width="20"
              height="20"
              alt=""
            />
            <h4 class="distributed">司机统计</h4>
          </div>
          <div class="item">
            <driverCount></driverCount>
          </div>
        </div>
        <div class="passenger">
          <div class="title-o">
            <img
              src="../assets/image/round.gif"
              width="20"
              height="20"
              alt=""
            />
            <h4 class="distributed">乘客统计</h4>
          </div>
          <div class="item">
            <passenger></passenger>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import amap from "@/components/amap.vue";
import market from "@/components/market.vue";
import business from "@/components/business.vue";
import Oddnumber from "@/components/OddNumber.vue";
import lastweek from "@/components/LastWeek.vue";
import driverCount from "@/components/driverCount.vue";
import passenger from "@/components/passenger.vue";
let _this;

export default {
  name: "Home",
  components: {
    amap,
    market,
    business,
    Oddnumber,
    lastweek,
    driverCount,
    passenger,
  },
  data() {
    return {
      order_num: "0000000000",
      flag:0,
    };
  },
  created() {
    _this = this;
    _this.getOrderNum()


  },
  methods: {
    getOrderNum() {

      _this.$post("/statistics/orderTotalNum")
        .then((res) => {

            _this.order_num = res.data.count
            // console.log(_this.order_num);
           setTimeout(() => {
              _this.getOrderNum()
            }, 2000);

        })
        .catch((err) => {});

    },
  },
};
</script>
<style scope>
.logo {
  color: #fff;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 4rem;
  color: transparent;
}
.home {
  display: flex;
  justify-content: space-between;
  background: url("../assets/image/bg-on.gif") 0 0 no-repeat,
    url("../assets/image/bg-tow.gif") 0 100% no-repeat;
  background-size: 100% 50%;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 100;
  overflow: hidden;
  padding: 5.5% 3.23% 2.5%;
  box-sizing: border-box;
}
.home > div {
  width: 25.83%;
  height: 100%;
}

.left,
.right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.left > div,
.right > div {
  width: 100%;
  height: 33%;
}
/* 市场分布 */
.market {
  text-align: center;
}
.title-o {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
}

.distributed {
  color: #fff;
  padding-left: 5px;
  box-sizing: border-box;
  font-size: 0.2rem;
}
.item {

  background: #1D2480;
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 单数对比 */
.oddnumb {
  text-align: center;
}

/* 上周收入 */
.Lastweek {
  text-align: center;
}

.right {
}
/* 业务占比 */
.busine {
  text-align: center;
}
.busine-item {
  width: 100%;
  height: 30%;
  background-color: rgb(7, 29, 76);
  display: flex;
  justify-content: center;
}
/* 司机统计 */
.driver {
  text-align: center;
}

/* 乘客统计 */
.passenger {
  width: 100%;
  height: 30%;
}
.home > .center {
  width: 45% !important;
}
.order-num {
  display: flex;
  justify-content: space-around;
  padding: 0 2%;
  box-sizing: border-box;
  transform: translateY(40px);
}
.order-num span {
  display: block;
  width: 62px;
  font-weight: bold;
  color: #ffffff;
  font-size: 0.5rem;
  background-image: url(../assets/image/order-num-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
}

.center-top-bg {
  height: 80px;
  background-image: url(../assets/image/center-top-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.center-top-bg p {
  font-size: 0.3rem;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  transform: translateY(40px);
  letter-spacing: 10px;
}

.map-img {
  display: block;
  width: 60%;
  margin: 0 auto;
  border: 0 !important;
  transform: translateY(-140px);
  z-index: 1;
}
.map-box {
  height: 62.5%;
}
.amap {
  height: 100%;
  /* transform: translateY(50px); */
  z-index: 1000;
}
</style>
