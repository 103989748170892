module.exports = {



  // config: {
  //   'localStorage_token_name': 'access_token',
  //   'axios_timeout': 20000, // 请求超时时间20秒
  //   'axios_baseURL': 'http://9z.v2.shao.tiantianapi.com',
  //   'axios_normalURL': 'http://normal.v2.shao.tiantianapi.com',
  //   'axios_imgURL': 'http://normal.v2.shao.tiantianapi.com/qiniu/upload',
  //   'hash_key': 'gcss9csffhhc9z',
  //   'webSocket': 'ws://192.168.0.40:9505?',
  //   'h5': 'http://192.168.0.40:8080/#/pages/modeInfo/modeInfo?app_version=1.0.1&platform=pc&port=admin&token=&order_mode_code='
  // }


  //王壮本地
  // config: {
  //   'axios_timeout': 20000, // 请求超时时间20秒
  //   'axios_baseURL': 'http://normal.3.jiuzhouapi.com',
  //  }

  // 庄
  // config: {
  //   'localStorage_token_name': 'access_token',
  //   'axios_timeout': 20000, // 请求超时时间20秒
  //   'axios_baseURL': 'http://192.168.111.99:8100',
  //   'axios_normalURL': 'http://192.168.111.99:8102',
  //   'axios_imgURL' : 'http://normal.3.jiuzhouapi.com/qiniu/upload',
  //   'hash_key': 'gcss9csffhhc9z',
  //   'webSocket': 'ws://192.168.111.99:9505?',
  //   'h5': 'http://192.168.0.40:8080/#/pages/modeInfo/modeInfo?app_version=1.0.1&platform=pc&port=admin&token=&order_mode_code='
  // }

  // dev开发环境
  // config: {
  //   'axios_timeout': 20000, // 请求超时时间20秒
  //   'axios_baseURL': 'https://normal.dev.jiuzhouapi.com',
  // }





  // ----------------------------------------- v3 ----------------------------------------------------------------



  config: {
    'axios_timeout': 20000, // 请求超时时间20秒
    'axios_baseURL': 'https://normal.v3.jiuzhoudaijiaapi.cn',
}



}
