<template>
  <!-- 单数对比 -->
  <div
    class="oddNum"
    id="oddNum"
  ></div>
</template>
<script>
import echarts from "echarts";
let _this;
export default {
  name: "OddNumber",
  data() {
    return {
      search_data: {
        page: 1,
        limit: 6,
      },
      option: {
        title: {
          text: "单位 : 单",
          right: "0",
          top: "10",

          textStyle: {
            color: "#00BFFF",
            fontSize: 14,
          },
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["上周", "本周"],
          top: "10",
          textStyle: {
            color: "#00BFFF",
            fontSize: window.innerHeight * 0.012,
          },
        },

        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["石家庄", "济南", "郑州", "秦皇岛", "西安", "长春", "太原"],
            axisLine: {
              lineStyle: {
                // 设置x轴颜色
                color: "#00BFFF",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: window.innerHeight * 0.012,
                fontWeight:'bold',
                color: "#fff",
              },
              formatter : function(params){
                   var newParamsName = "";// 最终拼接成的字符串
                            var paramsNameNumber = params.length;// 实际标签的个数
                            var provideNumber = 4;// 每行能显示的字的个数
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                            /**
                             * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                             */
                            // 条件等同于rowNumber>1
                            if (paramsNameNumber > provideNumber) {
                                /** 循环每一行,p表示行 */
                                for (var p = 0; p < rowNumber; p++) {
                                    var tempStr = "";// 表示每一次截取的字符串
                                    var start = p * provideNumber;// 开始截取的位置
                                    var end = start + provideNumber;// 结束截取的位置
                                    // 此处特殊处理最后一行的索引值
                                    if (p == rowNumber - 1) {
                                        // 最后一次不换行
                                        tempStr = params.substring(start, paramsNameNumber);
                                    } else {
                                        // 每一次拼接字符串并换行
                                        tempStr = params.substring(start, end) + "\n";
                                    }
                                    newParamsName += tempStr;// 最终拼成的字符串
                                }

                            } else {
                                // 将旧标签的值赋给新标签
                                newParamsName = params;
                            }
                            //将最终的字符串返回
                            return newParamsName
                }
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false, //去掉折线图中的横线
            },
            axisLine: {
              lineStyle: {
                // 设置y轴颜色
                color: "#87CEFA",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
              fontSize: window.innerHeight * 0.01,
              color: "#fff",
            },
            },
          },
        ],
        series: [
          {
            name: "上周",
            type: "line",
            stack: "总量",
            areaStyle: {
              color: "rgba(237, 149, 27, .3)",
            },
            symbol: "none",
            smooth: true,
            data: [120, 332, 1301, 3334, 390, 330, 320],
            itemStyle: {
              color: "rgba(237, 149, 27, 1)",
            },
          },
          {
            name: "本周",
            type: "line",
            stack: "总量",
            //   label: {
            //     normal: {
            //       show: true,
            //       position: "top",
            //     },
            //   },
            areaStyle: {
              color: "rgba(87, 44, 229, .3)",
            },
            smooth: true,
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            itemStyle: {
              color: "rgba(87, 44, 229, 1)",
              shadowColor: "rgba(87, 44, 229, .2)",
            },

            symbol: "none", //取消折点圆圈
          },
        ],
      },
    };
  },
  created() {
    _this = this;
  },
  mounted() {
    _this.getData()
  },
  methods: {
    getData() {
      _this
        .$post("/system/getOrderCount", _this.search_data)
        .then((res) => {
          _this.option.xAxis[0].data = [];

          _this.option.series[0].data = [];

          _this.option.series[1].data = [];

          for (let i = 0; i < res.data.result.length; i++) {
            if (res.data.result[i].city_name.length > 3) {
              _this.option.xAxis[0].data.push(
                res.data.result[i].city_name.slice(0, 3) + "..."
              );
            } else {
              // 城市名
              _this.option.xAxis[0].data.push(res.data.result[i].city_name);
            }

            // 上周数据
            _this.option.series[0].data.push(
              res.data.result[i].order_count
            );

            // 本周数据
            _this.option.series[1].data.push(res.data.result[i].new_order_count);
          }

          _this.renderEcharts();

          _this.$nextTick(function () {
            if (
              res.data.count >
              _this.search_data.page * _this.search_data.limit
            ) {
              // 如果总数 >  页码*每次请求数量
              _this.search_data.page += 1;
            } else {
              _this.search_data.page = 1;
            }

            setTimeout(() => {
              _this.getData();
            }, 5000);
          });
        })
        .catch((err) => {});
    },
    renderEcharts() {
      var myChart = echarts.init(document.getElementById("oddNum"));
      myChart.setOption(_this.option);
    },
  },
};
</script>
<style scoped>
.oddNum {
  width: 100%;
  height: 100%;
}
</style>

