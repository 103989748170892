<template>
  <div
    class="weekChart"
    id="weekChart"
  ></div>
</template>
<script>
import echarts from "echarts";
let _this;
export default {
  name: "LastWeek",
  data() {
    return {
      search_data: {
        page: 1,
        limit: 6,
      },
      option: {
        //     color: ['#00FFFF'],

        title: {
          text: "单位 : 元",
          right: "0",
          top: "10",

          textStyle: {
            color: "#00BFFF",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        //     grid: {
        //         left: '5%',
        //         right: '10%',
        //         bottom: '30%',
        //         containLabel: true
        //     },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                // 设置y轴颜色
                color: "#87CEFA",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: window.innerHeight * 0.012,
                fontWeight:'bold',
                color: "#fff",
                interval: 0,
             },
              formatter : function(params){
                   var newParamsName = "";// 最终拼接成的字符串
                            var paramsNameNumber = params.length;// 实际标签的个数
                            var provideNumber = 4;// 每行能显示的字的个数
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                            /**
                             * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                             */
                            // 条件等同于rowNumber>1
                            if (paramsNameNumber > provideNumber) {
                                /** 循环每一行,p表示行 */
                                for (var p = 0; p < rowNumber; p++) {
                                    var tempStr = "";// 表示每一次截取的字符串
                                    var start = p * provideNumber;// 开始截取的位置
                                    var end = start + provideNumber;// 结束截取的位置
                                    // 此处特殊处理最后一行的索引值
                                    if (p == rowNumber - 1) {
                                        // 最后一次不换行
                                        tempStr = params.substring(start, paramsNameNumber);
                                    } else {
                                        // 每一次拼接字符串并换行
                                        tempStr = params.substring(start, end) + "\n";
                                    }
                                    newParamsName += tempStr;// 最终拼成的字符串
                                }

                            } else {
                                // 将旧标签的值赋给新标签
                                newParamsName = params;
                            }
                            //将最终的字符串返回
                            return newParamsName
                }
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false, //去掉折线图中的横线
            },
            axisLine: {
              lineStyle: {
                // 设置y轴颜色
                color: "#87CEFA",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
              fontSize: window.innerHeight * 0.01,
              color: "#fff",
            },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "30%",
            data: [10, 52, 200, 334, 390, 330],
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#00FFFF" }, //柱图渐变色
                  { offset: 0.5, color: "#44C0C1" }, //柱图渐变色
                  { offset: 1, color: "#071D4C" }, //柱图渐变色
                ]),
              },
            },
          },
        ],
      },
    };
  },
  created() {
    _this = this;
  },
  mounted() {
    _this.getData();
  },
  methods: {
    getData() {
      // console.log(_this.option.xAxis.data);
      _this
        .$post("/system/getAmountCount", _this.search_data)
        .then((res) => {
          _this.option.xAxis[0].data = [];


          _this.option.series[0].data = [];

          for (let i = 0; i < res.data.result.length; i++) {
            if (res.data.result[i].city_name.length > 3) {
              _this.option.xAxis[0].data.push(
                res.data.result[i].city_name.slice(0, 3) + "..."
              );
            } else {
              // 城市名
              _this.option.xAxis[0].data.push(res.data.result[i].city_name);
            }



            // 本月数据
            _this.option.series[0].data.push(res.data.result[i].amount);
          }

          _this.renderEcharts();

          _this.$nextTick(function () {
            if (
              res.data.count >
              _this.search_data.page * _this.search_data.limit
            ) {
              // 如果总数 >  页码*每次请求数量
              _this.search_data.page += 1;
            } else {
              _this.search_data.page = 1;
            }

            setTimeout(() => {
              _this.getData();
            }, 5000);
          });
        })
        .catch((err) => {});
    },
    renderEcharts() {
      var myChart = echarts.init(document.getElementById("weekChart"));
      myChart.setOption(_this.option);
    },
  },
};
</script>

<style scoped>
.weekChart {
  width: 100%;
  height: 100%;
}
</style>